import axios from "axios";
import { useAuthStore } from "./stores/auth";
import { useGlobalStore } from "./stores/global";
import {
  sendToAnalytics,
  sendErrorToAnalytics,
} from "@/helpers/analyticsHelper";
import router from "@/router";

// Initialize variables
let failedQueue = [];
let countOfErrors = 0;

// Helper function to process the failed request queue
const processQueue = (error, token = null) => {
  const indicesToRemove = [];

  failedQueue.forEach((prom, index) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
    indicesToRemove.push(index);
  });

  // Remove items from the queue by index, starting from the last to avoid index shifting issues
  for (let i = indicesToRemove.length - 1; i >= 0; i--) {
    failedQueue.splice(indicesToRemove[i], 1);
  }
};

// Helper function to handle spinner display
const startStopSpinner = (display = "none") => {
  const spinner = document.getElementById("spinner");
  if (spinner) spinner.style.display = display;
};

// Axios instance for authenticated API calls
const apiBackendAuthAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST + "/v3",
});

// Request interceptor to append access token
apiBackendAuthAxios.interceptors.request.use(
  (config) => {
    startStopSpinner("block");
    const authStore = useAuthStore();
    if (authStore.accessToken) {
      config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }
    return config;
  },
  (error) => {
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Response interceptor for handling responses
apiBackendAuthAxios.interceptors.response.use(
  (response) => {
    handleSuccessResponse(response);
    return response;
  },
  (error) => handleErrorResponse(error)
);

// Axios instance for general API calls
const apiBackendAxios = axios.create({
  baseURL: import.meta.env.VITE_API_HOST,
});

// Request interceptor for general API calls
apiBackendAxios.interceptors.request.use(
  (config) => {
    startStopSpinner("block");
    return config;
  },
  (error) => {
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Response interceptor for general API calls
apiBackendAxios.interceptors.response.use(
  (response) => {
    startStopSpinner();
    return response;
  },
  (error) => {
    startStopSpinner();
    return Promise.reject(error);
  }
);

// Function to handle successful API responses
const handleSuccessResponse = (response) => {
  startStopSpinner();
  const globalStore = useGlobalStore();
  countOfErrors = 0;

  if (globalStore.isBackendDown) {
    globalStore.setBackendDown(false);
  }

  sendToAnalytics({
    method: response.config.method,
    url: response.config.url,
  });
};

// Function to handle API response errors
const handleErrorResponse = async (error) => {
  startStopSpinner();
  const globalStore = useGlobalStore();

  if (isUnauthorizedError(error)) {
    return handleUnauthorizedError(error);
  }

  if (isServerError(error)) {
    countOfErrors++;
    if (countOfErrors > 5 && !globalStore.isBackendDown) {
      globalStore.setBackendDown(true);
    }
  }

  return Promise.reject(error);
};

// Check if the error is a 401 Unauthorized error
const isUnauthorizedError = (error) =>
  error.response && error.response.status === 401;

// Handle 401 Unauthorized errors by validating the access token and refreshing if needed
const handleUnauthorizedError = async (error) => {
  const originalConfig = error.config;
  const globalStore = useGlobalStore();
  const authStore = useAuthStore();

  if (originalConfig._retry) {
    return retryWithNewToken(originalConfig);
  }

  originalConfig._retry = true;

  if (globalStore.isUpdatingAccessToken) {
    return queueRequest(originalConfig);
  }

  try {
    globalStore.setUpdatingAccessToken(true);

    const isValid = await validateAccessToken(authStore.accessToken);
    if (isValid) {
      return retryWithNewToken(originalConfig, authStore.accessToken);
    } else {
      const newToken = await refreshAccessToken();
      return retryWithNewToken(originalConfig, newToken);
    }
  } catch (refreshError) {
    processQueue(refreshError, null);
    authStore.setUnauthenticated(router);
    return Promise.reject(refreshError);
  } finally {
    globalStore.setUpdatingAccessToken(false);
  }
};

// Validate the current access token
const validateAccessToken = async (accessToken) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_HOST}/v3/test/probe`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  try {
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.status === 401) {
        console.error(
          `Token validation failed with status: ${response.status}, message: ${errorData.message}`
        );
        return false;
      }
      // TODO: Add handling for 500 errors
      console.error(`Token validation failed with status: ${response.status}`);
      return false;
    }

    const data = await response.json();

    // Check if the response contains "success: true"
    if (data && data.success === true) {
      return true; // The token is valid
    } else {
      console.error("Access token invalid, refreshing...");
      return false; // The token is invalid or there was another issue
    }
  } catch (error) {
    console.error("Error parsing token validation response:", error);
    return false; // Handle errors as invalid token
  }
};

// Retry the original request with a new access token
const retryWithNewToken = async (originalConfig, token) => {
  originalConfig.headers.Authorization = `Bearer ${token}`;
  return apiBackendAuthAxios(originalConfig);
};

// Refresh the access token with up to 3 retries on 500 errors
const refreshAccessToken = async () => {
  const authStore = useAuthStore();
  const MAX_REFRESH_RETRIES = 3;
  let refreshAttempt = 0;

  while (refreshAttempt < MAX_REFRESH_RETRIES) {
    try {
      const response = await apiBackendAxios.post("/auth/refreshtoken", {
        refresh_token: authStore.refreshToken,
      });

      const tokenData = response.data;
      await authStore.setAuthenticated(tokenData);
      await authStore.setUser();
      processQueue(null, authStore.accessToken);
      return authStore.accessToken;
    } catch (error) {
      refreshAttempt++;
      if (!isServerError(error) || refreshAttempt >= MAX_REFRESH_RETRIES) {
        authStore.setUnauthenticated(router);
        throw error; // Ensure the error is propagated after the final retry
      }
    }
  }
};

// queue the failed request to retry after the token is refreshed
const queueRequest = (originalConfig) => {
  return new Promise((resolve, reject) => {
    failedQueue.push({ resolve, reject });
  }).then((token) => {
    originalConfig.headers.Authorization = `Bearer ${token}`;
    return apiBackendAuthAxios(originalConfig);
  });
};

// Check if the error is a server-side error (500+)
const isServerError = (error) => error.response && error.response.status >= 500;

export { apiBackendAuthAxios, apiBackendAxios };
